.background {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.store-row > *{
  padding-bottom: 100px;
}

.appContainer
{
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-right: auto;
  margin-left: auto;
}

.navLogo
{
  color: #424242;
  font-style: italic;
  text-transform: uppercase;
  margin-bottom: 0px;
}

#navbar
{
  text-align: end;
  background-position: right;
  height: max-content;
}

.dropDownMenu hr
{
  margin-top:0px !important;
  margin-bottom: 10px;
  border: 1px solid #FFB94A;
}

video {
  position:relative;
  z-index:0;
  width:100%;
}
.overlay {
  text-align: center;
  position:absolute;
  z-index:1;
  background-color: #000000;
  opacity: 0.7;
  padding-top: 40px;
  padding-bottom: 40px;
  width: 100%;
}

.homePageIcons
{
  position:relative;
}

.background-mobile {
  padding-top: 0px;
  background: url(./img/backgroundWeb.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn--rounded {
  border-radius: 30px !important;
}

.myHeader-title
{
  white-space: initial;
  color: #fefeff;
}

.appHeader
{
  color:#ff5000;
  padding: 0px 20px 0px 20px;
}

.card-title
{
  font-size: 30px !important;
  font-weight: bold !important;
  margin-bottom: 0px !important;
}

.card-title.price
{

  font-size: 35px !important;
  font-weight: bold;
}

.appSub
{
  padding: 0px 20px 0px 20px;
}

.containerDiv {
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: 0px;
  margin-left: 0px;
}

.subtitle
{
  margin-left:180px;
  color: #FFB94A;
  font-size: 30px;
  font-family:Arial, Helvetica, sans-serif;
  font-style: italic;
  margin-bottom: 0px;
}

.btn--primary {
  font-family: 'Oxygen', sans-serif ;
  position: relative;
  z-index: 2;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  border-radius: 0;
  padding: 0;
  width: 170px;
  height: 45px;
  line-height: 44px;
}

.btn--primary {
  background-color: #FFB94A !important;
  color: #ffffff !important;
  border-color: #FFB94A !important;
}

.myHeader-subtitle {
  font-size: 22px;
  line-height: 32px;
  text-align: center;
  color: #fefffe;
  margin-bottom: 43px;
}

.priceSub
{
  color: #adacb6;
}

.cardListText
{
  text-align: left;
}

.dropDownMenu
{
  left: 10px !important;
  width: 70%;
  background-color: #212529 !important;
  color: #F90 !important;
  height: 100%;
}

.dropDownMenuIcon
{
  text-align: left;
  padding-top: 10px;
  padding-left: 10px;
}

.navBarIcon
{
  text-align: left !important;
}

.dropDownMenuContainer
{
  height: 100%;
  
}

.dropDownMenu div
{
  padding-left: 30px;
}


#dropDownBTN
{
  align-items: right !important;
  margin-left: -20px !important;
  padding-bottom: 10px;
  padding-top: 20px;
}

.footerLink
{
  cursor:pointer;
}

.dropDownMenuItem h5
{
  color: #F90 !important;
  font-size: 20px !important;
  padding-bottom: 10px;
}

.cardListIcon
{
  text-align: right;
}

.cardsColection
{
  margin-bottom: 100px;
}

.card-group
{
  padding-bottom: 120px;
}

.card
{
  box-shadow: 0 0 10px #868788;
  border: 2px solid #868788 !important;
  border-radius: 7px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  height: 500px;
}

p.myHeader-subtitle
{
  color: #000000 !important;
}

.homeItemsBox
{
  height: 150px;
  margin-top: 20px;
  margin-bottom: 20px !important;
  overflow: hidden !important;
}

.homeItemsContainer hr
{
  border-top: 2px solid #FFB94A;
}

.myHeader-btn {
  font-weight: bold !important;
  margin-bottom: 50px;
}

.myHeader-img
{
  width: 40% !important;
  text-align: center; 
}

.menu__list--animate-on{
  animation-name: exampleon;
  animation-duration: 500ms;
}

@keyframes exampleon {
  from 
  {
    opacity: 0;
    top:-50px;
  }
  to 
  {
    opacity: .9;
    top:0px;
  }
}

.menu__list--animate-off{
  animation-name: example-off;
  animation-duration: 100ms;
}

@keyframes example-off {
  from {opacity: .9;}
  to {opacity: 0;}
}

.space
{
  background-color: rgb(255, 255, 255);
}

.nav-link {
  color: #424242 !important;
  font-size: 18px;
  font-weight: 400;
  line-height: 50px;
  text-transform: uppercase !important;
  position: relative;
  display: block;
  font-family: Arial,sans-serif;
}

.navbar-brand-mobile {
  margin-right: 50px;
}


.colCust {
  padding-top: 35px !important;
  padding-right: 100px !important;
  padding-bottom: 35px !important;
  padding-left: 100px !important;
  background-color:#212529 !important;
}

.icons {
  padding-right: 4px !important;
}

.dropdown-menu {
  margin: 0 0 0 0px !important;
}

.applicationBox {
  height: fit-content;
  background-color: rgb(255, 255, 255);
  border: 1px solid;
  padding: 50px !important;
  box-shadow: 5px 10px #888888;
  margin-bottom: 50px;
  margin-top: 50px;
  font-family: 'Times New Roman', Times, serif;
  line-height: 1.6;
}

div.checkbox {
  display: inline-block;
  /*ie7 -- start*/
  zoom: 1;
}

div.checkbox > label {
  display: flex;
  flex-direction: row-reverse;
}

/*
 *  STYLE 2
 */

body::-webkit-scrollbar-track
{
  border-radius: 10px;
}

body::-webkit-scrollbar
{
  width: 12px;
}

body::-webkit-scrollbar-thumb
{
  background-color: #F90;	
	background-image: -webkit-linear-gradient(45deg,
	                                          rgba(255, 255, 255, .2) 25%,
											  transparent 25%,
											  transparent 50%,
											  rgba(255, 255, 255, .2) 50%,
											  rgba(255, 255, 255, .2) 75%,
											  transparent 75%,
											  transparent)
}

.myHeader-subtitle-mobile
{
  font-size: 15px;
  line-height: 32px;
  text-align: center;
  color: #adacb6;
  margin-bottom: 43px;
}